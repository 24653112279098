import Vue from "vue"
import App from "./App.vue"
import "./assets/sass/style.scss"
import "scroll-behavior-polyfill"

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount("#app")
