<template>
  <div>
    <!-- メニュー -->
    <MenuButton class="menu-button" @click="modal = true" />
    <Fade>
      <div v-if="modal" class="overlay">
        <CloseButton class="menu-button" @click="modal = false" />
        <div class="menu">
          <div class="menu-title">MENU</div>
          <div class="menu-list">
            <a class="menu-list-item" @click.prevent="jump('.owner', -48)"
              >オーナー様の写真</a
            >
            <a class="menu-list-item" @click.prevent="jump('.news', -48)"
              >NEWS</a
            >
            <a class="menu-list-item" @click.prevent="jump('.concept', -48)"
              >特徴</a
            >
            <a class="menu-list-item" @click.prevent="jump('.check', -48)"
              >点検</a
            >
            <a class="menu-list-item" @click.prevent="jump('.maintenance', -48)"
              >メンテナンス</a
            >
            <a class="menu-list-item" @click.prevent="jump('.reform', -48)"
              >リフォーム</a
            >
            <a
              class="menu-list-item"
              href="http://www.spec.to/contact"
              target="_blank"
              >お問い合わせ</a
            >
            <a
              class="menu-list-item"
              href="http://www.spec.to/company"
              target="_blank"
              >会社概要</a
            >
          </div>
        </div>
      </div>
    </Fade>

    <Fade>
      <div v-if="ownerDialog" class="overlay">
        <CloseButton class="menu-button" @click="ownerDialog = false" />
        <div class="owner-dialog">
          <div class="owner-dialog-title">写真一覧</div>
          <div>準備中</div>
        </div>
      </div>
    </Fade>

    <!-- ヒーローイメージ -->
    <div class="hero">
      <img class="title" src="./assets/images/hero/title.png" />
      <img class="logo" src="./assets/images/hero/logo.png" />
      <img class="message" src="./assets/images/hero/message.png" />
      <div class="hero__message">
        <div>スタッコフレックスは、世界中のどこへでも</div>
        <div>信頼と笑顔であふれた世界にするために…</div>
      </div>
    </div>

    <!-- オーナー様限定 -->
    <div class="owner">
      <div class="owner__text">
        <div class="owner__text--title">
          【スタッコフレックスオーナー様限定】
        </div>
        <div class="owner__text--pop">
          あなたのご自慢のお家をみんなに見せちゃいませんか!!
        </div>
        <div class="owner__text--detail">
          スタッコフレックス販売店のSNSで<br />
          皆様のお家をご紹介させていただきます。<br />
          もちろん防犯等、配慮させていただきますのでご安心ください。
        </div>
        <a class="owner__text--button" href="mailto:funada@spec.to">投稿する</a>
      </div>
      <div class="owner__thumbnail">
        <img src="./assets/images/owner/1.png" />
      </div>
      <div class="owner__thumbnail">
        <img src="./assets/images/owner/2.png" />
      </div>
      <div class="owner__thumbnail">
        <img src="./assets/images/owner/3.png" />
      </div>
      <a class="owner__more" @click.prevent="ownerDialog = true">MORE</a>
    </div>

    <!-- 新着情報 -->
    <div class="news">
      <div class="news-title">NEWS</div>
      <div class="news-list">
        <div class="news-list-item">
          <div class="news-list-item__date">2021/03/16</div>
          <div class="news-list-item__title">
            スタッコフレックスのオーナー様向けのHPが公開されました
          </div>
        </div>
      </div>
    </div>

    <!-- 概念説明 -->
    <div class="concept-wrapper">
      <div class="concept">
        <div class="concept-list">
          <div class="concept-list-item">
            <div class="concept-list-item__thumbnail">
              <img src="./assets/images/concept/1.png" />
            </div>
            <div class="concept-list-item__text">
              <div class="title">1982年に誕生した歴史ある塗り壁材</div>
              <div class="content">
                スタッコフレックスは塗り壁材として「雨のシアトル」の異名を持つ気候変動の激しい沿岸地域ワシントン州シアトルにて、スタッコフレックスインターナショナル社で開発され、1982年に発売されました。<br />
                高品質かつ高性能なスタッコフレックスは大切な住まいを美しいまま、永きにわたって守り続けることができます。<br />
                これまで50カ国以上で販売実績があり、世界中で愛されています。日本では2004年より株式会社スペックが本格的に販売をスタートしました。<br />
                愛知県豊橋市より、アメリカで作られたスタッコフレックスを一缶一缶、心を込めてお客様のもとへお届けします。
              </div>
            </div>
          </div>
          <div class="concept-list-item">
            <div class="concept-list-item__thumbnail">
              <img src="./assets/images/concept/2.png" />
            </div>
            <div class="concept-list-item__text">
              <div class="title">家を守り続けるための機能</div>
              <div class="content">
                スタッコフレックスは、超弾性塗り壁材（スタッコ）のため、従来の塗り壁材で発生していた亀裂の問題を優れた伸縮性能により最小限に抑えることができます。<br />
                純白で不純物を含まないカルシウムサンドの骨材と超弾性アクリルエマルジョンを配合することにより、どのような気候条件でも高い耐候性を発揮します。<br />
                <br />
                スタッコフレックスは経年変化による様々な問題を解消し、永く美しい外壁を維持します。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 境界画像 -->
    <div class="border">
      <img src="./assets/images/border.png" />
    </div>

    <!-- README -->
    <div class="readme">
      <div class="readme__title">
        “ご家族の笑顔のために”スマイルサポートブックをご活用してください。
      </div>
      <div class="readme-button-wrapper">
        <a class="readme-button wide" @click.prevent="jump('.check', -48)"
          >点検</a
        >
        <a class="readme-button" @click.prevent="jump('.maintenance', -48)"
          >メンテナンス</a
        >
        <a class="readme-button" @click.prevent="jump('.reform', -48)"
          >リフォーム</a
        >
      </div>
      <div class="readme__label">
        以下の注意点を必ずお読みいただくようお願いいたします。
      </div>
      <ul class="readme-list">
        <li>
          建物を譲渡したり賃貸する場合は、スマイルサポートブックを新しくお住まいになられる方に必ずお渡しください。
        </li>
        <li>
          改良のため、予告なく仕様を変更することがありますのでご了承ください。
        </li>
        <li>
          製品の不具合に関するお問い合わせは、施工業者までお願いいたします。
        </li>
      </ul>
    </div>

    <!-- 点検 -->
    <div class="check">
      <div class="check__half">
        <div class="check__title">
          <div class="thumbnail">
            <img src="./assets/images/readme/1.png" />
          </div>
          <div>
            <div class="title wide">点検</div>
            <div class="subtitle">お家を大切に、長持ちさせるために</div>
          </div>
        </div>
        <div class="check__text">
          新築したばかりのお家は柱や梁が乾燥し始めて大きく動いたり、近隣の交通や風の影響で毎日数ミリ単位で揺れています。<br />
          また、ALC造などでも構造のコンクリートから水蒸気を発しています。したがって、お家の外壁にはとてもストレスがかかっています。<br />
          <br />
          スタッコフレックスは伸縮性能に優れ、ひび割れに強い外壁材のため、その度に超弾性の機能を発揮します。<br />
          それでもスタッコフレックスも万能ではありませんので、時々何か変化がないか見て確認してください。<br />
          お施主様の小さな気遣いが、お家を長持ちさせます。<br />
        </div>
      </div>
      <div class="check__half">
        <div class="check__label">チェック項目</div>
        <ul class="check__list">
          <li>著しい汚れはありませんか。</li>
          <li>コケ、カビは生えてませんか。</li>
          <li>亀裂や破損はありませんか。</li>
          <li>キズはありませんか。</li>
          <li>膨れなど異常は見られませんか。</li>
          <li>塗り替えの必要はありませんか。</li>
        </ul>
      </div>
    </div>

    <!-- メンテナンス -->
    <div class="maintenance-wrapper">
      <div class="maintenance">
        <div class="maintenance__title">
          <div class="thumbnail">
            <img src="./assets/images/readme/2.png" />
          </div>
          <div>
            <div class="title">メンテナンス</div>
            <div class="subtitle">外壁の洗浄</div>
          </div>
        </div>
        <div class="maintenance__half">
          <div class="maintenance__subtitle">
            お家の外壁は洗ってあげてください
          </div>
          <div class="maintenance__text">
            スタッコフレックスは凸凹のある塗り壁材なので表面にホコリや排気ガス、雨だれなどの汚れがついてしまうことがあります。<br />
            その場合は中性洗剤や漂白剤などで洗うことができます。他の外壁材ではシミや色抜けになり薬剤がほとんど使えませんが、スタッコフレックスはその影響を受けにくいのも強みのひとつです。<br />
            <br />
            さらに高圧洗浄機をお持ちの方は、ご使用することができます。スタッコフレックスの割れにくい特徴と下地の強い接着力によって高圧洗浄機の圧力にも耐えてくれます。<br />
            これも他の外壁材にはできないことです。<br />
            「汚れたな」と思ったら、積極的に洗ってください。<br />
            <br />
            年に一回くらい洗うことで永くお家の外壁を美しく保つことができます。<br />
            <br />
            ※洗浄の際は中性洗剤と漂白剤は混ぜないようにしてください。また、各種洗剤は植栽に影響を与える場合がございます。ご注意ください。<br />
          </div>
          <div class="maintenance__subtitle">亀裂が入ってしまったら</div>
          <div class="maintenance__text">
            スタッコフレックスは伸縮性能に優れていますが、お家が大きく動いた時に外壁が割れてしまうことがあります。<br />
            <br />
            亀裂を見つけても心配しないでください。<br />
            通常は亀裂から水が家の中まで侵入しにくい構造になっています。ただ、そのまま放置すると水の侵入するリスクが高くなりますので、見つけた場合は、お家を建てた建築会社にご相談ください。出荷データを管理していますので建築会社とともに迅速に対応します。<br />
          </div>
        </div>
        <div class="maintenance__half">
          <div class="maintenance__subtitle">キズがついてしまったら</div>
          <div class="maintenance__text">
            何かが外壁にぶつかった！？<br />
            冗談みたいな話ですが、このようなご相談をいただく事があります。例えば、車や強風で樹木がこすれ、外壁をけずってしまう事例もありました。<br />
            <br />
            その場合はスタッコフレックスを上から塗ることで補修できます。専門知識が必要なのでこちらも建築会社にご相談ください。<br />
          </div>
          <div class="maintenance__subtitle">膨れを見つけたら</div>
          <div class="maintenance__text">
            外壁に見慣れない膨れができちゃった！？<br />
            めったにありませんがご注意ください。<br />
            スタッコフレックスは透湿性があり、水蒸気は通しますが、液体は通さない防水性を持っています。<br />
            膨れができたということは、雨漏りの可能性がありますので、見つけた場合はすぐにお家を建てた建築会社にご連絡ください。<br />
          </div>
        </div>
      </div>
    </div>

    <!-- リフォーム -->
    <div class="reform">
      <div class="reform__half">
        <div class="reform__img">
          <img src="./assets/images/owner/1.png" />
        </div>
      </div>
      <div class="reform__half">
        <div class="reform__title">
          <div class="thumbnail">
            <img src="./assets/images/readme/3.png" />
          </div>
          <div>
            <div class="title">リフォーム</div>
          </div>
        </div>
        <div class="reform__subtitle">いつかくる「塗り替えの時期」に備えて</div>
        <div class="reform__text">
          スタッコフレックスは、日本国内で流通する外壁材の中でも耐久性の高い材料になります。それでも十数年経過すると少なからず不具合が生じてきます。<br />
          その時はスタッコフレックスで塗り替えができます。<br />
          <br />
          色を替えるだけならスタッコフレックスリニューがあります。スタッコフレックス同様に超弾性の塗料のため外壁を長持ちさせることができます。<br />
          壁の質感から変えたい場合もスタッコフレックスなら可能です。どちらも建築会社にご相談ください。<br />
        </div>
      </div>
    </div>

    <!-- フッタ -->
    <div class="footer-wrapper">
      <div class="footer">
        <div class="footer-link-wrapper">
          <div class="footer-link wide">
            <a href="http://www.spec.to/contact" target="_blank">
              <div class="footer-link__button">お問い合わせはこちら</div>
            </a>
          </div>
          <div class="footer-link">
            <a href="https://stucoflex.jp" target="_blank">
              <img
                class="footer-link__img"
                src="./assets/images/footer/1.png"
              />
            </a>
          </div>
          <div class="footer-link">
            <a href="http://www.spec.to" target="_blank">
              <img
                class="footer-link__img"
                src="./assets/images/footer/2.png"
              />
            </a>
          </div>
        </div>
        <img class="footer-logo" src="./assets/images/footer/logo.png" />
        <div class="copyright">©2020 STUCOFLEX. All rights reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
import Fade from "./components/Fade.vue"
import MenuButton from "./components/MenuButton.vue"
import CloseButton from "./components/CloseButton.vue"

export default {
  components: {
    Fade,
    MenuButton,
    CloseButton,
  },
  data() {
    return {
      modal: false,
      ownerDialog: false,
    }
  },
  methods: {
    jump(query, additional = 0) {
      window.scrollTo({
        top: (document.querySelector(query)?.offsetTop ?? 0) + additional,
        behavior: "smooth",
      })
      this.modal = false
    },
  },
}
</script>
